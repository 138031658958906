@keyframes rotate {
    from {
        transform: rotate(0deg);
    } to {
        transform: rotate(360deg);
    }
}

.bg-picker {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    gap: 10px;
    margin: 30px;

    div {
        cursor: pointer;
        border: 3px solid rgba(0, 0, 0, 0.151);
        border-radius: 50%;
        height: 50px;
        width: 50px;
    }

    .selected {
        border: 3px dashed black;
        transition: transform 2s ease-in-out;
        animation: rotate 14s linear 0s infinite forwards;
    }

    .bg-backdrop {
        display: none;
    }
}

@media screen and (max-width: 1000px) {
    .bg-picker {
        position: fixed;
        margin: 13px 20px;

        div {
            height: 15px;
            width: 15px;
        }

        .bg-backdrop {
            z-index: -1;
            border: none;
            display: block;
            opacity: 1;
            position: absolute;
            top: -20px;
            right: -20px;
            width: 150px;
            height: 55px;
            background-color: var(--transparent-gray);
            border-radius: 0 0 0 20px;
        }
    }
}