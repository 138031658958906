@keyframes fadeIn {
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }
}

.web {
    font-size: var(--medium);

    h1 {
        font-size: calc(1.2*var(--medium));
        font-weight: 360;
    }

    .web-intro {
        max-width: 60vw;
        font-size: var(--small);
        margin-bottom: 40px;
        font-style: italic;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .web-projects {
        max-width: 1000px;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
}

@media screen and (max-width: 1000px) {
    .web {
        .web-intro {
            max-width: none;
        }
    }
}
