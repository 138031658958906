@keyframes rotate {
    from {
        transform: rotate(0deg);
    } to {
        transform: rotate(360deg);
    }
}

.circle {
    --radius: 110px;
    --icon: 50px;

    cursor: pointer;
    position: fixed;
    right: calc(-1*var(--radius));
    bottom: calc(-1*var(--radius));
    width: calc(2*var(--radius));
    height: calc(2*var(--radius));
    border: 3px dashed black;
    border-radius: 50%;
    animation: rotate 18s linear 0s infinite forwards;
    transition: scale 0.4s ease-in-out;
    background-color: var(--transparent-gray);

    .icon {
        height: var(--icon);
        position: absolute;
    }

    .github {
        top: calc(0.1*var(--radius));
        left: calc(var(--radius) - 0.5*var(--icon));
    }

    .email {
        top: calc(var(--radius) - 0.5*var(--icon));
    }

    .email-1 {
        left: calc(0.1*var(--radius));
        transform: rotate(-90deg);
    }

    .email-2 {
        right: calc(0.1*var(--radius));
        transform: rotate(90deg);
    }

    .insta {
        bottom: calc(0.1*var(--radius));
        left: calc(var(--radius) - 0.5*var(--icon));
        transform: rotate(180deg);
    }
}

.circle:hover {
    scale: 1.2;
}

@media screen and (max-width: 1000px) {
    .circle {
        --radius: 75px;
        --icon: 35px;
    }
}

// @media screen and (max-width: 600px) {
//     .circle {
//         --icon: 25px;
//     }
// }

// @media screen and (max-width: 500px) {
//     .circle {
//         --icon: 20px;
//     }
// }