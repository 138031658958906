.web-project {
    width: fit-content;

    .project-title {
        cursor: pointer;
        font-size: var(--medium);
        font-weight: 350;
    }

    .project-title:hover {
        text-decoration: underline;
    }

    .project-stack {
        font-size: var(--small);
        color: #626262e9;
    }

    .project-description {
        margin-top: 5px;
        font-size: calc(0.7*var(--small));
        background-color: #ab605b85;
        padding: 10px;
        border-radius: 5px;
        max-width: fit-content;
    }
    
    .project-links {
        font-size: var(--small);
        margin-top: 5px;
        display: flex;
        gap: 20px;

        .project-link,
        .project-link:visited {
            color: blue;
        }

        .project-link:hover {
            color: #20c20e;
        }
    }
}

.web-project.expanded {

    .project-title {
        text-decoration: underline;
    }
}