@font-face {
    font-family: 'Fira';
    src: url('../assets/fonts/fira/FiraCode-VariableFont_wght.ttf');
    font-weight: 300 700;
}

@font-face {
    font-family: 'Fairwall';
    src: url('../assets/fonts/fairwall/FairwallSans-Regular.otf');
}

@font-face {
    font-family: 'Helico';
    src: url('../assets/fonts/helico/Helico-Centrica-Roman.ttf');
}

@font-face {
    font-family: 'Jost';
    src: url('../assets/fonts/jost/Jost-VariableFont_wght.ttf');
    font-weight: 100 900;
}

@font-face {
    font-family: 'Unbounded';
    src: url('../assets/fonts/unbounded/Unbounded-VariableFont_wght.ttf');
    font-weight: 200 900;
}


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

:root {
    --rvv: calc(1rem + 1vmin + 1vmax);

    --padding: calc(var(--rvv));
    --nav-padding: 15px;

    --large: calc(1.1*var(--rvv));
    --medium: calc(0.9*var(--rvv));
    --small: calc(0.7*var(--rvv));

    --green: #719a5a;
    --gray: #949494;
    --red: #824E4A;
    --orange: #dca500;
    --transparent-gray: #aeaeae46;
}

html,
body {
    height: 100%;
    min-height: calc(100vh - 2*var(--body-padding));
    min-width: 100vw;
    max-width: 100vw;
    box-sizing: border-box;
    overflow-x: hidden;
}

body {
    font-weight: 250;
    font-size: var(--large);
}

.wrapper {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    
    main {
        display: flex;
        gap: 40px;
    }
}

@media screen and (max-width: 1000px) {
    main {
        flex-direction: column;
    }
}