.header {
    display: flex;
    padding: var(--padding);
    font-weight: 100;
    
    .name {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: calc(var(--rvv));

        .name-0:hover {
            color: var(--green);
        }

        .name-1:hover {
            color: var(--gray);
        }

        .name-2:hover {
            color: var(--red);
        }

        .delanty:hover {
            color: rgba(0, 0, 0, 0.128);
        }
    }

    .name:hover {
        .stephen,
        .brian {
            transform: rotate(180deg);
        }
    }
}

@media screen and (max-width: 1000px) {
    header.header {
        padding: calc(1.9*var(--padding)) 0 0;
        justify-content: center;

        .name {
            gap: calc(0.6*var(--rvv));
            max-width: 80vw;
        }
    }
}