.photo {
    font-size: var(--medium);
    
    .photos-container {
        max-width: 1200px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 20px;
        align-items: flex-start;

        img {
            max-height: 250px;
        }

        p {
            margin: 50px;
            font-style: italic;
            text-align: center;
    
            a,
            a:visited {
                color: blue;
            }
    
            a:hover {
                color: #20c20e;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .photo {
        .photos-container {
            justify-content: center;
        }
    }
}