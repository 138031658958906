@keyframes hover {
    from {
        transform: translate(0, 0) rotate(-10deg);
    } to {
        transform: translate(10px, -10px) rotate(10deg);
    }
}

.about-section {
    font-size: var(--medium);
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 1000px;
}

@media screen and (max-width: 1000px) {
    .about-section {
        font-size: var(--small);
        align-items: center;
        text-align: center;

        .thanks {
            font-size: var(--medium);
        }

        p {
            max-width: 80vw;
        }
    }
}