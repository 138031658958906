@keyframes rotate {
    from {
        transform: rotate(0deg) scale(1.1);
    } to {
        transform: rotate(360deg) scale(1.1);
    }
}

nav {
    margin-top: 20px;
    width: fit-content;
    min-width: 130px;
    overflow: hidden;
    
    ul {
        margin: 10px 0;
        padding-left: calc(var(--padding) - 5px);
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: calc(2*var(--rvv));

        li {
            width: var(--large);
            height: var(--large);
            padding: calc(0.7*var(--nav-padding));
            display: flex;
            justify-content: center;
            align-items: center;
            border: 3px solid transparent;
            border-radius: 50%;
            cursor: pointer;
            transition: transform 0.1s ease-in-out;
        }

        li:not(.selected):hover {
            transform: scale(1.1);
        }

        li.selected {
            transform: scale(1.1);
            color: transparent;
            animation: rotate 18s linear 0s infinite reverse;
        }

        .web.selected {
            border: 3px dashed var(--red);
            text-shadow: 0px 0px var(--red);
        }

        .food.selected {
            border: 3px dashed var(--gray);
            text-shadow: 0px 0px var(--gray);
        }

        .photo.selected {
            border: 3px dashed var(--orange);
            text-shadow: 0px 0px var(--orange);
        }

        .about.selected {
            border: 3px dashed #719a5a;
            text-shadow: 0 0 #719a5a;
        }
    }
}

@media screen and (max-width: 1000px) {
    nav {                
        ul {
            margin-top: 10px;
            padding: 0;
            flex-direction: row;
            width: 100vw;
            justify-content: center;
            gap: 15px;
        }
    }
}