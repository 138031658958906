@keyframes rotate {
    from {
        transform: rotate(0deg);
    } to {
        transform: rotate(360deg);
    }
}

.font-picker {
    position: absolute;
    top: 75px;
    right: 0;
    display: flex;
    gap: 10px;
    margin: 30px;

    div {
        position: relative;
        cursor: pointer;
        border: 3px solid rgba(0, 0, 0, 0.151);
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--medium);

        .helico {
            position: relative;
            top: 6px;
            left: 2px;
        }

        .unbounded {
            font-size: calc(0.9*var(--medium));
        }
    }

    .selected {
        border: 3px dashed black;
        transition: transform 2s ease-in-out;
        animation: rotate 18s linear 0s infinite reverse;
    }

    .font-backdrop {
        display: none;
    }
}

@media screen and (max-width: 1000px) {
    .font-picker {
        position: fixed;
        top: 0;
        left: 0;
        margin: 13px 20px;
        
        div {
            height: 15px;
            width: 15px;
            border: 3px solid transparent;

            .helico {
                position: relative;
                top: 4px;
                left: 2px;
            }
        }

        .selected {
            border: 3px dashed transparent;
            text-decoration: underline;
            text-decoration-style: dashed;
            animation: none;
        }

        .font-backdrop {
            z-index: -1;
            border: none;
            border-left: none;
            border-top: none;
            display: block;
            opacity: 1;
            position: absolute;
            top: -20px;
            left: -20px;
            width: 150px;
            height: 55px;
            background-color: var(--transparent-gray);
            border-radius: 0 0 20px 0;
        }
    }
}